import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

function Login() {
    useEffect(() => {
        if (localStorage.getItem('user_info')) {
            window.location.href ="/home"
        }
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    async function login1() {
    const item = {email, password};
        if (email.length===0 || password.length===0) 
        {
            Toast.fire({
                icon: 'error',
                title: 'Please Enter Username Or Password'
            })
        }
         else 
        {
            let result = await fetch("https://shreedbackend.blueviperstechnology.com/api/login", {
                method: 'POST',
                body: JSON.stringify(item),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            result = await result.json();
            if (result === 1 || result ==='') {
                Swal.fire({
                    title: 'Sorry!',
                    text: 'Please Enter Correct Username or Password',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            } else {
                localStorage.setItem("user_info", JSON.stringify(result))

                Toast.fire({
                    icon: 'success',
                    title: 'Your Are Successfully Login'
                })
                window.location.href = "/home";
            }
      }
    }

    return (
        <>
            <body className="hold-transition login-page" >
                <div className="login-box">
                    {/* /.login-logo */}
                    <div className="card card-outline card-primary">
                        <div className="card-header text-center">
                            <a href="#" className="h1"><b>shri </b> DIGITAL</a>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email"  value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" onClick={login1} className="btn btn-primary btn-block">Sign In</button>
                                    </div>
                                </div>
                            <br />
                            {/* <p className="mb-1">
                                <a href="/forgot-password" class="text-center">I forgot my password</a>
                            </p>
                            <p className="mb-0">
                                <a href="#" className="text-center">Register a new membership</a>
                            </p> */}
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
                {/* /.login-box */}

            </body>

        </>
    )
}

export default Login;